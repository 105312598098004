import { Controller } from "@hotwired/stimulus"
import { Datepicker } from 'vanillajs-datepicker';
import LocaleFr from 'vanillajs-datepicker/locales/fr';

Datepicker.locales.fr = LocaleFr.fr;

// Connects to data-controller="calendar"
export default class extends Controller {
  connect() {
    this.element.setAttribute("autocomplete", "off");

    new Datepicker(this.element, {
        format: 'yyyy-mm-dd',
        language: 'fr',
        autohide: true,
        minDate: this.element.dataset.minDate,
        maxDate: this.element.dataset.maxDate
      }
    );
  }
}
