require("@hotwired/turbo-rails");
require("@rails/activestorage").start();
import "../controllers"

require('@popperjs/core');
require('bootstrap');
require('cocoon-js-vanilla');

require('./theme/main');
require('./libs/tomselect');
require('./libs/trix');
require('./utils/hideable-field');
