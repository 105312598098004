document.addEventListener("turbo:load", function() {
    const checkallCheckboxes = document.querySelectorAll('.checkall')
    for (const checkallCheckbox of checkallCheckboxes) {
        checkallCheckbox.addEventListener('click', () => {
            const checkboxes = document.querySelectorAll(checkallCheckbox.dataset.target);
            for (const checkbox of checkboxes) {
                checkbox.checked = checkallCheckbox.checked;
            }
        });
    }
});