import TomSelect from 'tom-select';

// Instantiate Tom-Select using the 'tomselect' class
// Also add the 'multiple' class for multiple selection.
document.addEventListener( "turbo:load", function () {
  let config_multiple = {
    plugins: ['remove_button']
  };
  let config_simple = {}

  document.querySelectorAll( ".tomselect:not(.tomselect-ajax)" ).forEach( elem => {
    const ts = new TomSelect(elem, elem.getAttribute("multiple") ? config_multiple : config_simple);

    // Behavior improvement for multiple
    ts.on("item_add", function () {
      if (elem.multiple) {
        this.input.nextElementSibling.querySelector("input").value = "";
        this.refreshOptions()
      }
    })
  });

  document.querySelectorAll( ".tomselect-ajax" ).forEach( elem => {
    const baseUrl = elem.dataset.url;
    const extraField = document.querySelector(elem.dataset.extraField);
    const extraFieldName = (() => {
      if (!extraField) return;
      if (!extraField.name.includes('[')) return extraField.name;

      return extraField.name.substring(extraField.name.indexOf('[') + 1, extraField.name.length - 1);
    })();

    const ts = new TomSelect(elem, {
      ...(elem.getAttribute("multiple") ? config_multiple : config_simple),
      labelField: 'text',
      valueField: 'value',
      searchField: 'text',
      preload: true,
      load: (query, callback) => {
        const extraFieldParams = extraFieldName ? `&${extraFieldName}=${extraField.value}` : '';
        const url = `${baseUrl}?qs=${encodeURIComponent(query)}${extraFieldParams}`;
        fetch(url)
            .then(response => response.json())
            .then(json => {
              callback(json.items);
            }).catch(()=>{
          callback();
        });
      }
    });

    // Behavior improvement for multiple
    ts.on("item_add", function () {
      if (elem.multiple) {
        this.input.nextElementSibling.querySelector("input").value = "";
        this.refreshOptions()
      }
    })

    if (extraField && extraFieldName) {
      // Reload options when the extra field changes. Debouncing should be considered if it's an input.
      extraField.addEventListener('change', () => {
        // Clear selected option
        ts.clear();
        // Clear all options
        ts.clearOptions();
        // Refresh options
        ts.load('');
      });
      // Clear options on load, but not the preselected one.
      ts.clearOptions();
    }
  });
});
