document.addEventListener("turbo:load", function () {
  document.querySelectorAll("[data-visible]").forEach((hideableElem) => {
    const sourceElement = document.querySelector(hideableElem.dataset.visible);
    const visibleValues = JSON.parse(hideableElem.dataset.visibleValues);

    sourceElement.addEventListener('change', (e) => {
      const newValue = e.target.value;

      if (visibleValues.includes(newValue)) {
        // hideableElem.removeAttribute('disabled');
        hideableElem.parentElement.style.removeProperty('display');
      } else {
        hideableElem.value = undefined;
        hideableElem.parentElement.style.display = 'none';
      }
    });

    const initialEvent = new Event('change');
    sourceElement.dispatchEvent(initialEvent);
  });
});